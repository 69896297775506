<template>
    <div>
        <AboutNav />
        <main class="col-lg-10 col-md-9 col-sm-9 left_gutter main fill">
            <h1>Shipping</h1>

            <h4>We deliver your curbs efficiently and when you need them!</h4>

            <div class="img_content_right">
                <a :href="`${$cdiVars.BASE_URL}/pdf/cdi_transit_map_2021.pdf`"><img class="img-responsive" :src="`${$cdiVars.BASE_URL}/images/v3/cdi_transit_map_2021.png`"></a>

                <div class='table-responsive spacing_top'>
                    <table class='table'>
                        <tbody>
                        <tr>
                            <th>Carrier</th>
                            <th>Site</th>
                            <th>Phone #</th>
                        </tr>
                        <tr>
                            <td>Dayton Freight</td>
                            <td>
                                <a href='http://www.daytonfreight.com'>daytonfreight.com</a>
                            </td>
                            <td>800.860.5102</td>
                        </tr>
                        <tr>
                            <td>Estes Freight</td>
                            <td>
                                <a href='http://www.estes-express.com'>estes-express.com</a>
                            </td>
                            <td>866.378.3748</td>
                        </tr>
                        <tr>
                            <td>LME</td>
                            <td>
                                <a href='http://www.lme4me.com/'>lme4me.com/</a>
                            </td>
                            <td>800.888.4950</td>
                        </tr>
                        <tr>
                            <td>ABF Freight</td>
                            <td>
                                <a href='https://www.abfs.com'>abfs.com</a>
                            </td>
                            <td>800.610.5544</td>
                        </tr>
                        <tr>
                            <td>Oak Harbor Freight</td>
                            <td>
                                <a href='http://www.oakh.com'>oakh.com</a>
                            </td>
                            <td>800.858.8815</td>
                        </tr>
                        <tr>
                            <td>South Eastern Freight</td>
                            <td>
                                <a href='http://www.sefl.com'>sefl.com</a>
                            </td>
                            <td>800.637.7335</td>
                        </tr>
                        <tr>
                            <td>Manitoulin transport</td>
                            <td>
                                <a href='http://www.manitoulintransport.com'>manitoulintransport.com</a>
                            </td>
                            <td>800.265.1485</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <h4>No surprise freight charges!</h4>
            <p>
                At CDI we learned early to include the price of freight in your curb estimates so there are no charges later that you didn't anticipate. This is one example of our continuous efforts to improve customer service.
            </p>
            <p>
                When it comes to shipping at CDI, we utilize a dedicated team of our own in house logistics experts to ensure your products arrive on time, not damaged and on budget. We utilize the following delivery methods to ensure your product will meet our standards.
            </p>
            <ol class="list_content">
                <li>LTL</li>
                <li>PL</li>
                <li>TL Van</li>
                <li>TL Flat Bed</li>
            </ol>
            <p>
                Your logistics team will track your product daily to ensure that items are moving as expected each day. If something has gone wrong, they will interject and take appropriate steps to keep the product moving and keep you aware of any interruptions.
            </p>
            <p>
                You will also receive an emailed confirmation notice when your product has shipped. This email will include ETA, Shipping PRO #, and a link to your carriers' web sites for real time tracking.
            </p>

            <p>
                You can click the "My Orders" button online, enter your PO # or search for the order you are looking for. Click on the Track Button and it will direct you to the carrier that delivered your product, when it delivered and who signed for it.
            </p>

            <h4>Your Carriers</h4>
            <p>
                We use local, regional and national carriers to deliver your products when you need them and we constantly review the performance of those selected carriers. If for some reason we have a carrier that is not meeting our rigid standards, we then make decisions to bring in additional carriers to accommodate your delivery needs.
            </p>

            <p>
                <b>Carriers are held to stringent goals when it comes to</b>
                On Time Delivery (95%), Damage Rate (&lt;0.50%), and Cost of Freight.
            </p>

            <p>
                <b>Optional Services available include:</b> Please contact CDI for any of these.
            </p>
            <ol class="list_content">
                <li>Dedicated teams to run non stop</li>
                <li>Air Freight for the extremely time sensitive item</li>
                <li>Guaranteed Delivery</li>
                <li>Time Specific Arrival</li>
                <li>Anything else that is humanly possible to have your curb deliver when you need it.</li>
            </ol>
        </main>
    </div>
</template>

<script>
import AboutNav from '@/components/content/AboutNav.vue'
export default {
    name: "About",
    components: {
        AboutNav,
    },
    created() {
        this.$appStore.setTitle(['CDI Shipping and Logistics Experts']);
    },
}
</script>

<style scoped>

</style>